<template>
  <tr class="">
    <td class="flex align-items-center">
      <div class="checkbox">
        <input type="checkbox" :id="'checkbox' + unique + recomposer.id" v-model="isChecked"/>
        <label :for="'checkbox' + unique + recomposer.id"><span class="checkbox-icon"></span></label>
      </div>
    </td>
    <td class="p-2 whitespace-nowrap font-semibold">
      <router-link :to="{name: 'AdminRecomposerEdit', params: {id: recomposer.id}}">
        {{ recomposer.title }}
      </router-link>
    </td>
    <td class="p-2 whitespace-nowrap">
      {{ timeago(recomposer.created_at) }}
    </td>
    <td class="p-2 whitespace-nowrap">
      <router-link class="mr-3" :to="{name: 'PeopleDetail', params: {slug: recomposer.slug, prefix: 'soan-gia'}}">
        Xem
      </router-link>
      <router-link :to="{name: 'AdminRecomposerEdit', params: {id: recomposer.id}}">
        Sửa
      </router-link>
    </td>
  </tr>
</template>

<script>

import {
  timeago
} from "../../../core/services/utils.service";

export default {
  name: "AdminRecomposerItem",
  props: {
    recomposer: Object,
    unique: String
  },
  data() {
    return {
      isChecked: false
    }
  },
  methods: {
    timeago(x) {
      return timeago(x);
    }
  },
  created() {
    this.isChecked = this.recomposer.is_checked;
  },
  watch: {
    "recomposer.is_checked": {
      handler(newVal) {
        this.isChecked = newVal;
      },
      deep: true
    },
    isChecked: {
      handler(newVal) {
        this.$emit("handleCheckedRecomposer", this.recomposer, newVal);
      }
    },
  }
}
</script>
